import React from 'react';
import { Pane } from 'evergreen-ui';
import { Header, Button, Grid, Responsive, Container } from 'semantic-ui-react';
import Layout from '../../components/Layout';
import EventCard from '../../components/EventCard';
import CreateEventButton from '../../components/CreateEventButton';
import useEvents from '../../hooks/useEvents';

const Discover: React.FC<{}> = () => {
  const [events] = useEvents();

  return (
    <Layout activeItem="events" paneProps={{ marginTop: 0 }}>
      <Responsive as={Container} minWidth={Responsive.onlyTablet.minWidth}>
        <Pane height="5rem" />
      </Responsive>
      <Responsive as={Container} {...Responsive.onlyMobile}>
        <Pane height="2rem" />
      </Responsive>
      <Grid>
        <Grid.Column computer={10} mobile={16} verticalAlign="middle">
          <Header as="h1">Events</Header>
        </Grid.Column>
        <Grid.Column computer={3} mobile={16} verticalAlign="middle">
          <Button secondary fluid>
            Filter
          </Button>
        </Grid.Column>
        <Grid.Column computer={3} mobile={16} verticalAlign="middle">
          <CreateEventButton fluid />
        </Grid.Column>
      </Grid>
      <Grid stackable columns={3} style={{ marginTop: '24px' }}>
        {events.map((event) => (
          <Grid.Column key={event.manifest}>
            <EventCard event={event} />
          </Grid.Column>
        ))}
      </Grid>
      <Pane height="2rem" />
    </Layout>
  );
};

export default Discover;
