import React from 'react';
import { Pane } from 'evergreen-ui';
import { Container, Dropdown, Menu, MenuItemProps } from 'semantic-ui-react';
import { useWeb3React } from '@web3-react/core';
import { injected, portis } from '../../connectors';
import Logo from '../../svg/Logo';

interface NavbarProps {
  activeItem?: string;
  onItemClick?: (event: React.MouseEvent, data: MenuItemProps) => void;
}

const Navbar: React.FC<NavbarProps> = ({ activeItem, onItemClick }) => {
  const { account, activate, active, deactivate } = useWeb3React();

  return (
    <Container>
      <Menu secondary pointing stackable>
        <Menu.Item name="logo">
          <Logo width="1.6rem" />
        </Menu.Item>
        <Menu.Item name="events" active={activeItem === 'events'} onClick={onItemClick} />
        {/* <Menu.Item name="marketplace" active={activeItem === 'marketplace'} onClick={onItemClick} />
        <Menu.Item name="faq" active={activeItem === 'faq'} onClick={onItemClick} />
        <Menu.Item name="blog" active={activeItem === 'blog'} onClick={onItemClick} /> */}
        <Menu.Menu position="right">
          <Menu.Item
            name="account"
            active={activeItem === 'account'}
            onClick={onItemClick}
            position="right"
            content="My Account"
          />
          <Menu.Item>
            <Pane display="flex" flexDirection="column" justifyContent="center">
              <Dropdown
                text={
                  active && account
                    ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
                    : 'Connect Wallet'
                }
              >
                {active ? (
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => deactivate()} text="Disconnect" />
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => activate(injected)} text="MetaMask" />
                    <Dropdown.Item onClick={() => activate(portis)} text="Portis" />
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </Pane>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Container>
  );
};

export default Navbar;
