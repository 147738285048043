import React from 'react';
import { useHistory } from 'react-router-dom';
import { Pane, PaneProps } from 'evergreen-ui';
import { Container, Image, Responsive, Sidebar } from 'semantic-ui-react';
import Navbar from '../Navbar';
import { Helmet } from 'react-helmet';
import NavbarMobile from '../NavbarMobile';

interface LayoutProps {
  activeItem?: string;
  backgroundImage?: string;
  paneProps?: PaneProps;
}

const Layout: React.FC<LayoutProps> = ({ children, activeItem, backgroundImage, paneProps }) => {
  const history = useHistory();

  const handleItemClick = React.useCallback(
    (event, data) => {
      event.preventDefault();
      history.push(`/${data.name}`);
    },
    [history],
  );

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Eventdrip" />
        <meta property="og:title" content="Eventdrip" />
        <meta
          property="og:description"
          content="Eventdrip is a platform for creators to launch and monetize premium event experiences online As a content creator it’s hard to self-host, market, and monetize products and services. Eventdrip makes it easy for anyone to create, promote, and livestream interactive virtual events. Easily sell your tickets and merch, earn crypto, and provide premium experiences for fans willing to spend a little more for exclusivity. Request an invite to join today!"
        />
        <meta property="og:image" content="https://eventdrip.com/wp-content/uploads/2020/05/eventdrip-homepage.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Responsive {...Responsive.onlyMobile}>
        <NavbarMobile activeItem={activeItem} onItemClick={handleItemClick} />
        <Pane height="70px" />
      </Responsive>
      <Sidebar.Pushable>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Navbar activeItem={activeItem} onItemClick={handleItemClick} />
        </Responsive>
        <Sidebar.Pusher dimmed={false}>
          {backgroundImage && <Image src={backgroundImage} fluid />}
          <Container>
            <Pane marginTop="60px" {...paneProps}>
              {children}
            </Pane>
          </Container>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
  return (
    <>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Navbar activeItem={activeItem} onItemClick={handleItemClick} />
      </Responsive>
      <Responsive {...Responsive.onlyMobile}>
        <NavbarMobile activeItem={activeItem} onItemClick={handleItemClick} />
      </Responsive>
      {backgroundImage && <Image src={backgroundImage} fluid />}
      <Container>
        <Pane marginTop="60px" {...paneProps}>
          {children}
        </Pane>
      </Container>
    </>
  );
};

export default Layout;
