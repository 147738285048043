import React from 'react';
import { Grid, Header, StrictGridColumnProps } from 'semantic-ui-react';

const Info: React.FC<{
  title: string;
  subtitle?: string;
  computer: StrictGridColumnProps['computer'];
  mobile: StrictGridColumnProps['mobile'];
}> = ({ children, computer, subtitle, title, mobile }) => {
  return (
    <Grid.Column computer={computer} mobile={mobile}>
      <Header as="h5">{title}</Header>
      {subtitle && (
        <Header as="h2" className="info">
          {subtitle}
        </Header>
      )}
      {children}
    </Grid.Column>
  );
};

export default Info;
