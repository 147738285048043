import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import getIPFS from '../utils/ipfs';
import getContract from '../utils/getContract';
import EventDropTicketJSON from '../contracts/EventDripTicket.json';
import contractAddress from '../contracts/contract-address.json';
import { ITicket } from '../types';

function useTickets(): [ITicket[], boolean] {
  const web3React = useWeb3React<Web3Provider>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [tickets, setTickets] = React.useState<ITicket[]>([]);
  const ipfs = getIPFS();

  React.useEffect(() => {
    const bootstrap = async () => {
      if (loading && web3React.account) {
        const contract = getContract(web3React, contractAddress.EventDripTicket, EventDropTicketJSON.abi);
        const balanceOf = await contract.balanceOf(web3React.account);
        const _tickets = [];
        for (let i = 0; i < balanceOf; i++) {
          const token = await contract.tokenOfOwnerByIndex(web3React.account, i);
          const manifest = await contract.manifestOf(token);
          const event = await ipfs.get(manifest);
          _tickets.push({ manifest, token: token.toString(), event });
        }
        setTickets(_tickets);
        setLoading(false);
      }
    };
    bootstrap();
  }, [web3React, ipfs, loading]);

  return [tickets, loading];
}

export default useTickets;
