import React from 'react';
import { Pane } from 'evergreen-ui';
import { Header, Grid, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useMerch from '../../hooks/useMerch';
import Layout from '../../components/Layout';
import MerchCard from '../../components/MerchCard';

const Merch: React.FC<{}> = () => {
  const [merch, loading] = useMerch();
  return (
    <Layout activeItem="account">
      <Pane display="flex" alignItems="center" justifyContent="space-between">
        <Header as="h1">My Account</Header>
      </Pane>
      <Menu secondary pointing>
        <Menu.Item as={Link} name="Purchased Tickets" to="/account" />
        <Menu.Item as={Link} name="My Events" to="/account/events" />
        <Menu.Item as={Link} name="My Merch" to="/account/merch" active />
      </Menu>
      <Grid stackable columns={3} style={{ marginTop: '24px' }}>
        {loading && <h3>Loading...</h3>}
        {merch.map((item) => (
          <Grid.Column key={item.token}>
            <MerchCard merch={item} />
          </Grid.Column>
        ))}
      </Grid>
    </Layout>
  );
};

export default Merch;
