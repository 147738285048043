import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import getIPFS from '../utils/ipfs';
import getContract from '../utils/getContract';
import EventDropJSON from '../contracts/EventDrip.json';
import contractAddress from '../contracts/contract-address.json';
import { IInventory } from '../types';

function useInventory(manifest: string | undefined): [IInventory[], boolean] {
  const web3React = useWeb3React<Web3Provider>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [inventory, setInventory] = React.useState<IInventory[]>([]);
  const ipfs = getIPFS();

  React.useEffect(() => {
    const bootstrap = async () => {
      if (loading && manifest) {
        const contract = getContract(web3React, contractAddress.EventDrip, EventDropJSON.abi);
        const inventoryCount = await contract.getInventoryCount(manifest);
        const _inventory = [];
        for (let i = 0; i < inventoryCount; i++) {
          const item = await contract.getInventory(manifest, i);
          const data = await ipfs.get(item.manifest);
          _inventory.push({ manifest, merchManifest: item.manifest, inventory: item, data });
        }
        setInventory(_inventory);
        setLoading(false);
      }
    };
    bootstrap();
  }, [web3React, ipfs, loading, manifest]);

  return [inventory, loading];
}

export default useInventory;
