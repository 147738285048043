import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useEagerConnect, useInactiveListener } from './hooks/web3';
import Routes from './pages';

const App: React.FC<{}> = () => {
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Routes />
      </Router>
    </MuiPickersUtilsProvider>
  );
};

export default App;
