import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Account from '../pages/Account';
import Discover from '../pages/Discover';
import Event from '../pages/Event';

const Routes: React.FC<{}> = () => {
  return (
    <Switch>
      <Route exact path="/events" component={Discover} />
      <Route exact path="/event/:hash" component={Event} />
      <Route exact path="/account" component={Account.Tickets} />
      <Route exact path="/account/events" component={Account.Events} />
      <Route exact path="/account/merch" component={Account.Merch} />
      <Redirect exact path="/" to="/events" />
    </Switch>
  );
};

export default Routes;
