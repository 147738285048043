import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import getIPFS from '../utils/ipfs';
import getContract from '../utils/getContract';
import EventDropJSON from '../contracts/EventDrip.json';
import contractAddress from '../contracts/contract-address.json';
import { IEvent } from '../types';

function useEvents(): [IEvent[], boolean] {
  const web3React = useWeb3React<Web3Provider>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [events, setEvents] = React.useState<IEvent[]>([]);
  const ipfs = getIPFS();

  React.useEffect(() => {
    const bootstrap = async () => {
      if (loading) {
        const contract = getContract(web3React, contractAddress.EventDrip, EventDropJSON.abi);
        const eventLength = await contract.activeEventsLength();
        const _events = [];
        for (let i = 0; i < eventLength; i++) {
          const manifest = await contract.activeEventsAt(i);
          const event = await contract.getEvent(manifest);
          const data = await ipfs.get(manifest);
          _events.push({ manifest, event, data });
        }
        setEvents(_events);
        setLoading(false);
      }
    };
    bootstrap();
  }, [web3React, ipfs, loading]);

  return [events, loading];
}

export default useEvents;
