import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import getIPFS from '../utils/ipfs';
import getContract from '../utils/getContract';
import EventDropMerchJSON from '../contracts/EventDripMerch.json';
import contractAddress from '../contracts/contract-address.json';
import { IMerch } from '../types';

function useMerch(): [IMerch[], boolean] {
  const web3React = useWeb3React<Web3Provider>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [merch, setMerch] = React.useState<IMerch[]>([]);
  const ipfs = getIPFS();

  React.useEffect(() => {
    const bootstrap = async () => {
      if (loading && web3React.account) {
        const contract = getContract(web3React, contractAddress.EventDripMerch, EventDropMerchJSON.abi);
        const balanceOf = await contract.balanceOf(web3React.account);
        const _merch = [];
        for (let i = 0; i < balanceOf; i++) {
          const token = await contract.tokenOfOwnerByIndex(web3React.account, i);
          const manifest = await contract.manifestOf(token);
          const data = await ipfs.get(manifest);
          _merch.push({ manifest, token: token.toString(), data });
        }
        setMerch(_merch);
        setLoading(false);
      }
    };
    bootstrap();
  }, [web3React, ipfs, loading]);

  return [merch, loading];
}

export default useMerch;
