import React from 'react';
import { Pane } from 'evergreen-ui';
import { Card, Image, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { IEvent } from '../../types';
import getIPFS from '../../utils/ipfs';

const EventCard: React.FC<{
  event: IEvent;
}> = ({ event }) => {
  const history = useHistory();
  const ipfs = getIPFS();
  const backgroundImage = event ? ipfs.url(event.data.image) : undefined;
  const handleBuyTickets = React.useCallback(() => {
    history.push(`/event/${event.manifest}`);
  }, [history, event]);
  return (
    <Card fluid>
      <Image src={backgroundImage} wrapped ui={false} className={'event-card-image'} />
      <Card.Content>
        <Card.Meta>
          <Pane display="flex" justifyContent="space-between">
            <span className="date">{moment(event.data.date).format('lll')}</span>
            <span>{event.data.price} DAI</span>
          </Pane>
        </Card.Meta>
        <Card.Header>{event.data.name}</Card.Header>
        <Card.Description>{event.data.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button secondary fluid onClick={handleBuyTickets}>
          Buy Tickets
        </Button>
      </Card.Content>
    </Card>
  );
};

export default EventCard;
