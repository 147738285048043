import React from 'react';
import IconSVG from '../icons/eventdrip-drops-logo.svg';

interface IconProps {
  width: number | string;
}
const Logo: React.FC<IconProps> = ({ width }) => {
  return <img alt="Logo" width={width} src={IconSVG} />;
};

export default Logo;
