import React from 'react';
import { Pane } from 'evergreen-ui';
import { Header, Grid, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useOwnerEvents from '../../hooks/useOwnerEvents';
import Layout from '../../components/Layout';
import EventCard from '../../components/EventCard';

const Events: React.FC<{}> = () => {
  const [events, loading] = useOwnerEvents();
  return (
    <Layout activeItem="account">
      <Pane display="flex" alignItems="center" justifyContent="space-between">
        <Header as="h1">My Account</Header>
      </Pane>
      <Menu secondary pointing>
        <Menu.Item as={Link} name="Purchased Tickets" to="/account" />
        <Menu.Item as={Link} name="My Events" to="/account/events" active />
        <Menu.Item as={Link} name="My Merch" to="/account/merch" />
      </Menu>
      <Grid stackable columns={3} style={{ marginTop: '24px' }}>
        {loading && <h3>Loading...</h3>}
        {events.map((event) => (
          <Grid.Column key={event.manifest}>
            <EventCard event={event} />
          </Grid.Column>
        ))}
      </Grid>
    </Layout>
  );
};

export default Events;
