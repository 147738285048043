import React from 'react';
import { Header, Button, Grid, Container } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Pane } from 'evergreen-ui';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import blockies from 'ethereum-blockies';
import Layout from '../../components/Layout';
import PurchaseTicketButton from '../../components/PurchaseTicketButton';
import CreateMerchButton from '../../components/CreateMerchButton';
import AddToCalendarButton from '../../components/AddToCalendarButton';
import StartEventButton from '../../components/StartEventButton';
import FinalizeEventButton from '../../components/FinalizeEventButton';
import WithdrawButton from '../../components/WithdrawButton';
import useEvent from '../../hooks/useEvent';
import useInventory from '../../hooks/useInventory';
import useTickets from '../../hooks/useTickets';
import getIPFS from '../../utils/ipfs';
import InventoryCard from '../../components/InventoryCard';
import Info from '../../components/Info';

const Event: React.FC<{}> = () => {
  const { hash } = useParams();
  const ipfs = getIPFS();
  const { account } = useWeb3React<Web3Provider>();
  const [event, eventLoading] = useEvent(hash);
  const [inventory] = useInventory(event?.manifest);
  const [tickets] = useTickets();
  const backgroundImage = event ? ipfs.url(event.data.image) : undefined;
  const isOwner = account ? account === event?.event.owner : false;
  const hasTicket = tickets.findIndex((ticket) => ticket.manifest === event?.manifest) > -1;
  const hasStarted = event?.event.started || false;
  const hasFinalized = event?.event.finalized || false;
  const ticketsSold = event ? `${event.event?.ticketsIssuedCount}/${event.event?.ticketsMaxLimit}` : 'N/A';
  const balance = event && isOwner ? event.balance / 10 ** 18 : 0;
  // TODO: Implement stream
  // const showStream = hasStarted && !hasFinalized;

  if (eventLoading) {
    return <h3>Loading</h3>;
  }

  return (
    <Layout backgroundImage={backgroundImage} paneProps={{ marginTop: '2rem' }}>
      <Helmet>
        <title>{`${event?.data.name} | EventDrip`}</title>
        <meta property="og:title" content={`${event?.data.name} | EventDrip`} />
        <meta property="og:description" content={event?.data.description} />
        <meta property="og:image" content={backgroundImage} />
      </Helmet>
      <Grid>
        <Grid.Column computer={10} mobile={16} verticalAlign="middle">
          <Header.Subheader>{moment(event?.data.date).format('lll')}</Header.Subheader>
          <Header as="h1" style={{ marginTop: '0.4rem' }}>
            {event?.data.name}
          </Header>
        </Grid.Column>
        <Grid.Column computer={3} mobile={16} verticalAlign="middle">
          <Button secondary fluid>
            Share
          </Button>
        </Grid.Column>
        {!hasStarted && !hasFinalized && (
          <Grid.Column computer={3} mobile={16} verticalAlign="middle">
            <PurchaseTicketButton fluid event={event} />
          </Grid.Column>
        )}
        {hasTicket && !hasStarted && !hasFinalized && (
          <Grid.Column computer={3} mobile={16} verticalAlign="middle">
            <AddToCalendarButton fluid />
          </Grid.Column>
        )}
        {isOwner && !hasFinalized && (
          <Grid.Column computer={3} mobile={16} verticalAlign="middle">
            <CreateMerchButton event={event} fluid />
          </Grid.Column>
        )}
        {isOwner && !hasStarted && !hasFinalized && (
          <Grid.Column computer={3} mobile={16} verticalAlign="middle">
            <StartEventButton event={event} fluid />
          </Grid.Column>
        )}
        {isOwner && hasStarted && !hasFinalized && (
          <Grid.Column computer={3} mobile={16} verticalAlign="middle">
            <FinalizeEventButton event={event} fluid />
          </Grid.Column>
        )}
        {isOwner && (
          <Grid.Column computer={3} mobile={16} verticalAlign="middle">
            <WithdrawButton event={event} fluid />
          </Grid.Column>
        )}
      </Grid>
      <Pane height="2rem" />
      <Grid>
        <Info title="Owner" computer={2} mobile={6}>
          <img
            alt="event-owner-icon"
            src={blockies
              .create({
                seed: account,
                color: '#dfe',
                bgcolor: '#aaa',
                size: 15,
                scale: 3,
              })
              .toDataURL()}
          />
        </Info>
        <Info title="Ticket Price" subtitle={`${event?.data?.price} DAI`} computer={3} mobile={6} />
        <Info title="Tickets Sold" subtitle={ticketsSold} computer={3} mobile={6} />
        {isOwner && <Info title="Balance" subtitle={`${balance} DAI`} computer={3} mobile={6} />}
      </Grid>
      <Pane height="2rem" />
      <Pane>
        <Header as="h5">Description</Header>
        <Container>
          <p className="paragraph1">{event?.data.description}</p>
        </Container>
      </Pane>
      <Pane height="4rem" />
      {!hasFinalized && inventory.length > 0 && (
        <Pane>
          <Header as="h5">Merch</Header>
          <Container className="horizontal-flex-scrollable">
            {inventory.map((item, index) => (
              <InventoryCard item={item} key={item.merchManifest} index={index} event={event} />
            ))}
          </Container>
        </Pane>
      )}
      <Pane height="2rem" />
    </Layout>
  );
};

export default Event;
