import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { PortisConnector } from '@web3-react/portis-connector';

const POLLING_INTERVAL = 12000;
const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.REACT_APP_RPC_URL_1 as string,
  4: process.env.REACT_APP_RPC_URL_4 as string,
  42: process.env.REACT_APP_RPC_URL_42 as string,
};

export const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42, 31337] });

export const network = new NetworkConnector({
  urls: { 1: RPC_URLS[1], 4: RPC_URLS[4], 42: RPC_URLS[42] },
  defaultChainId: 42,
  pollingInterval: POLLING_INTERVAL,
});

export const portis = new PortisConnector({
  dAppId: process.env.REACT_APP_PORTIS_DAPP_ID as string,
  networks: [1, 42, 100],
});
