import React from 'react';
import { Card, Image, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { IMerch } from '../../types';
import getIPFS from '../../utils/ipfs';

const MerchCard: React.FC<{
  merch: IMerch;
}> = ({ merch }) => {
  const history = useHistory();
  const ipfs = getIPFS();
  const { data } = merch;
  const backgroundImage = data ? ipfs.url(data.image) : undefined;
  const handleGoToEvent = React.useCallback(() => {
    if (merch?.event?.manifest) {
      return history.push(`/event/${merch.event.manifest}`);
    }
  }, [history, merch]);
  return (
    <Card fluid>
      <Image src={backgroundImage} wrapped ui={false} className="event-card-image" />
      <Card.Content>
        <Card.Header>{data.name}</Card.Header>
        <Card.Description>{data.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button secondary fluid onClick={handleGoToEvent}>
          See Event
        </Button>
      </Card.Content>
    </Card>
  );
};

export default MerchCard;
