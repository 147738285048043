import React from 'react';
import { Pane } from 'evergreen-ui';
import { Header, Grid, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useTickets from '../../hooks/useTickets';
import Layout from '../../components/Layout';
import TicketCard from '../../components/TicketCard';

const Tickets: React.FC<{}> = () => {
  const [tickets, loading] = useTickets();
  return (
    <Layout activeItem="account">
      <Pane display="flex" alignItems="center" justifyContent="space-between">
        <Header as="h1">My Account</Header>
      </Pane>
      <Menu secondary pointing>
        <Menu.Item as={Link} name="Purchased Tickets" to="/account" active />
        <Menu.Item as={Link} name="My Events" to="/account/events" />
        <Menu.Item as={Link} name="My Merch" to="/account/merch" />
      </Menu>
      <Grid stackable columns={3} style={{ marginTop: '24px' }}>
        {loading && <h3>Loading...</h3>}
        {tickets.map((ticket) => (
          <Grid.Column key={ticket.token}>
            <TicketCard ticket={ticket} />
          </Grid.Column>
        ))}
      </Grid>
    </Layout>
  );
};

export default Tickets;
