import React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import getContract from '../../utils/getContract';
import EventDripJSON from '../../contracts/EventDrip.json';
import ERC20JSON from '../../contracts/ERC20.json';
import contractAddress from '../../contracts/contract-address.json';

const PurchaseTicketButton: React.FC<ButtonProps> = (buttonProps) => {
  const { event } = buttonProps;
  const web3React = useWeb3React<Web3Provider>();
  const handlePurchaseTicket = React.useCallback(async () => {
    try {
      const tokenBits = utils.bigNumberify(10).pow(18);
      const ticketPrice = utils.bigNumberify(event!.data.price).mul(tokenBits);
      const token = getContract(web3React, event!.event.tokenAddress, ERC20JSON.abi);
      const eventDrip = getContract(web3React, contractAddress.EventDrip, EventDripJSON.abi);
      await token.approve(contractAddress.EventDrip, ticketPrice);
      await eventDrip.purchaseTicket(event!.manifest);
      // TODO: Replace this with proper state reloading
      window.location.reload();
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  }, [event, web3React]);

  return (
    <Button primary onClick={handlePurchaseTicket} {...buttonProps}>
      Buy Ticket
    </Button>
  );
};

export default PurchaseTicketButton;
