import ipfsClient from 'ipfs-http-client';
import { getBytes32FromIpfsHash, getIpfsHashFromBytes32 } from './hash';

function getIPFS(host = 'ipfs.infura.io', port = 5001, protocol = 'https') {
  const ipfs = ipfsClient({ host, port, protocol });

  return {
    add: async (data: any) => {
      const res = await ipfs.add(JSON.stringify(data));
      return getBytes32FromIpfsHash(res[0].hash);
    },
    get: async (hash: string) => {
      const ipfsHash = getIpfsHashFromBytes32(hash);
      const res = await ipfs.cat(ipfsHash);
      return JSON.parse(res.toString());
    },
    upload: async (image: File) => {
      const imageBuffer = await image.arrayBuffer();
      const res = await ipfs.add(imageBuffer);
      return res[0].hash;
    },
    url: (hash: string) => {
      return `${protocol}://${host}/ipfs/${hash}`;
    },
  };
}

export function getIPFSUrl(hash: String) {
  return `https://ipfs.infura.io/ipfs/${hash}`;
}

export default getIPFS;
