import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import getIPFS from '../utils/ipfs';
import getContract from '../utils/getContract';
import EventDropJSON from '../contracts/EventDrip.json';
import contractAddress from '../contracts/contract-address.json';
import { IEvent } from '../types';

function useEvent(hash: string): [IEvent | null, boolean] {
  const web3React = useWeb3React<Web3Provider>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [event, setEvent] = React.useState<IEvent | null>(null);
  const ipfs = getIPFS();

  React.useEffect(() => {
    const bootstrap = async () => {
      if (!event) {
        const contract = getContract(web3React, contractAddress.EventDrip, EventDropJSON.abi);
        const response = await contract.getEvent(hash);
        const data = await ipfs.get(hash);
        if (response.owner === web3React.account) {
          const balance = await contract.getBalance(hash);
          setEvent({ manifest: hash, balance, event: response, data });
          setLoading(false);
        } else {
          setEvent({ manifest: hash, event: response, data });
          setLoading(false);
        }
      }
    };
    bootstrap();
  }, [web3React, ipfs, hash, event]);

  return [event, loading];
}

export default useEvent;
