import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Box, TextField, FormControl, FormLabel, FormHelperText, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import getIPFS from '../../utils/ipfs';
import styles from './NewMerchModal.module.scss';

export interface IMerchFormData {
  name: string;
  image: string;
  description: string;
  price: number;
  maxLimit: number;
}

const NewEventModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Record<string, any>) => void;
  loading: boolean;
}> = ({ open, onClose, onSubmit, loading }) => {
  const ipfs = getIPFS();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const { register, handleSubmit, watch, setValue, errors } = useForm<IMerchFormData>();
  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);
      const image = await ipfs.upload(acceptedFiles[0]);
      setUploading(false);
      setValue('image', image);
    },
    [ipfs, setValue],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const hasImage = watch('image');

  React.useEffect(() => {
    register({ name: 'image' }, { required: 'Required' });
  }, [register]);

  return (
    <Modal as="form" size="tiny" open={open} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <Modal.Header>Create Merch</Modal.Header>
      <Modal.Content>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            name="name"
            inputRef={register({ required: 'Required' })}
            label="Merch Name"
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            name="description"
            label="Merch Description"
            multiline
            rows={4}
            inputRef={register({ required: 'Required' })}
            error={!!errors.description}
            helperText={errors.description && errors.description.message}
          />
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <FormLabel error={!!errors.image}>Image</FormLabel>
          <Box
            {...getRootProps()}
            height="65px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop some files here, or click to select files</Typography>
            )}
            {uploading && <Typography variant="subtitle1">Uploading</Typography>}
            {hasImage && <Typography variant="subtitle1">Image Selected</Typography>}
          </Box>
          {errors.image && <FormHelperText error>{errors.image.message}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            type="number"
            name="price"
            label="Merch Price"
            inputRef={register({ required: 'Required' })}
            error={!!errors.price}
            helperText={errors.price && errors.price.message}
          />
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            type="number"
            name="maxLimit"
            inputRef={register({ required: 'Required' })}
            label="Quantity"
            error={!!errors.maxLimit}
            helperText={errors.maxLimit && errors.maxLimit.message}
          />
        </FormControl>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button primary type="submit" loading={loading || uploading}>
          Create Merch
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewEventModal;
