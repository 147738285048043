import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  InputLabel,
  FormHelperText,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Moment } from 'moment';
import getIPFS, { getIPFSUrl } from '../../utils/ipfs';
import styles from './NewEventModal.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export interface IEventFormData {
  name: string;
  image: string;
  date: Moment;
  description: string;
  price: number;
  capacity: number;
  absorbFees: boolean;
}

const NewEventModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Record<string, any>) => void;
  loading: boolean;
}> = ({ open, onClose, onSubmit, loading }) => {
  const ipfs = getIPFS();
  const { register, handleSubmit, watch, setValue, errors } = useForm<IEventFormData>();
  const [eventDate, setEventDate] = React.useState<Moment | null>(null);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      setUploading(true);
      const image = await ipfs.upload(acceptedFiles[0]);
      setUploading(false);
      setValue('image', image);
    },
    [ipfs, setValue],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const hasImage = watch('image');

  React.useEffect(() => {
    register({ name: 'absorbFees' }, { required: 'Required' });
    register({ name: 'date' }, { required: 'Required' });
    register({ name: 'image' }, { required: 'Required' });
  }, [register]);

  const handleDateInput = React.useCallback(
    (date) => {
      setValue('date', date as Moment);
      setEventDate(date as Moment);
    },
    [setValue, setEventDate],
  );

  return (
    <Modal
      as="form"
      size="tiny"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Create Event</Modal.Header>
      <Modal.Content>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            name="name"
            inputRef={register({ required: 'Required' })}
            label="Event Name"
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <DateTimePicker
            label="Event Date"
            name="date"
            value={eventDate}
            onChange={handleDateInput}
            error={!!errors.date}
            helperText={errors.date && (errors.date as any)?.message}
          />
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            name="description"
            label="Event Description"
            multiline
            rows={4}
            inputRef={register({ required: 'Required' })}
            error={!!errors.description}
            helperText={errors.description && errors.description.message}
          />
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <FormLabel error={!!errors.image}>Image</FormLabel>
          <Box
            {...getRootProps()}
            padding="16px 0px"
            display="flex"
            marginTop="8px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop some files here, or click to select files</Typography>
            )}
            {uploading && <LinearProgress color="secondary" classes={{ root: styles.imageLoader }} />}
            {!uploading && hasImage && (
              <Box padding="1.5rem 0">
                <LazyLoadImage effect="blur" alt="img-event" height={200} src={getIPFSUrl(hasImage)} />
              </Box>
            )}
          </Box>
          {errors.image && <FormHelperText error>{errors.image.message}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            type="number"
            name="price"
            label="Ticket Price"
            inputRef={register({ required: 'Required' })}
            error={!!errors.price}
            helperText={errors.price && errors.price.message}
          />
        </FormControl>
        <FormControl fullWidth classes={{ root: styles.formControl }}>
          <TextField
            type="number"
            name="capacity"
            inputRef={register({ required: 'Required' })}
            label="Capacity"
            error={!!errors.capacity}
            helperText={errors.capacity && errors.capacity.message}
          />
        </FormControl>
        <FormControl fullWidth error={!!errors.absorbFees} classes={{ root: styles.formControl }}>
          <InputLabel id="absorbFees">Fee Handling</InputLabel>
          <Select
            labelId="absorbFees"
            label="Fee Handling"
            defaultValue="0"
            onChange={(e) => {
              setValue('absorbFees', e.target.value === '1');
            }}
            error={!!errors.absorbFees}
          >
            <MenuItem value="0" selected>
              Pass fees on to attendees
            </MenuItem>
            <MenuItem value="1">Absorb fees</MenuItem>
          </Select>
          {errors.absorbFees && <FormHelperText>{errors.absorbFees.message}</FormHelperText>}
        </FormControl>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button primary type="submit" loading={loading} disabled={uploading}>
          Create Event
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewEventModal;
