import { ethers } from 'ethers';
const { NODE_ENV } = process.env;

function getContract(context: any, address: string, abi: any): ethers.Contract {
  let signerOrProvider;
  if (context.active) {
    const provider = new ethers.providers.Web3Provider(context.library?.provider!);
    signerOrProvider = provider.getSigner();
  } else {
    if (NODE_ENV === 'production') {
      signerOrProvider = new ethers.providers.InfuraProvider('kovan', '208c40947a3a42b192a83b75190d01b6');
    } else {
      signerOrProvider = new ethers.providers.JsonRpcProvider('http://localhost:8545');
    }
  }
  return new ethers.Contract(address, abi, signerOrProvider);
}

export default getContract;
