import React from 'react';
import { Button } from 'semantic-ui-react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import getContract from '../../utils/getContract';
import EventDripJSON from '../../contracts/EventDrip.json';
import contractAddress from '../../contracts/contract-address.json';
import ButtonProps from '../../types/ButtonProps';

const WithdrawButton: React.FC<ButtonProps> = (buttonProps) => {
  const { event, style } = buttonProps;
  const web3React = useWeb3React<Web3Provider>();
  const handleWithdrawal = React.useCallback(async () => {
    try {
      const eventDrip = getContract(web3React, contractAddress.EventDrip, EventDripJSON.abi);
      await eventDrip.withdrawBalance(event!.manifest, event!.balance);
      // TODO: Replace this with proper state reloading
      window.location.reload();
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  }, [event, web3React]);

  return (
    <Button primary onClick={handleWithdrawal} style={style} {...buttonProps}>
      Withdraw
    </Button>
  );
};

export default WithdrawButton;
