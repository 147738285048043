import React from 'react';
import { Pane } from 'evergreen-ui';
import { Card, Image, Button } from 'semantic-ui-react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import { IInventory, IEvent } from '../../types';
import getContract from '../../utils/getContract';
import EventDripJSON from '../../contracts/EventDrip.json';
import ERC20JSON from '../../contracts/ERC20.json';
import contractAddress from '../../contracts/contract-address.json';
import getIPFS from '../../utils/ipfs';

const InventoryCard: React.FC<{
  item: IInventory;
  event: IEvent | null;
  index: number;
}> = ({ item, event, index }) => {
  const ipfs = getIPFS();
  const web3React = useWeb3React<Web3Provider>();
  const backgroundImage = item ? ipfs.url(item.data.image) : undefined;
  const handlePurchaseMerch = React.useCallback(async () => {
    try {
      const tokenBits = utils.bigNumberify(10).pow(18);
      const merchPrice = utils.bigNumberify(item.inventory.price).mul(tokenBits);
      const token = getContract(web3React, event!.event.tokenAddress, ERC20JSON.abi);
      const eventDrip = getContract(web3React, contractAddress.EventDrip, EventDripJSON.abi);
      await token.approve(contractAddress.EventDrip, merchPrice);
      await eventDrip.purchaseMerch(item.manifest, index);
      // TODO: Replace this with proper state reloading
      window.location.reload();
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  }, [web3React, event, item, index]);

  return (
    <Card className="inventory-card" fluid>
      <Image src={backgroundImage} wrapped ui={false} />
      <Card.Content>
        <Card.Meta>
          <Pane display="flex" justifyContent="space-between">
            <span>{item.data.price} DAI</span>
          </Pane>
        </Card.Meta>
        <Card.Header>{item.data.name}</Card.Header>
        <Card.Description>{item.data.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button secondary fluid onClick={handlePurchaseMerch}>
          Buy
        </Button>
      </Card.Content>
    </Card>
  );
};

export default InventoryCard;
