import React, { useState } from 'react';
import { Pane } from 'evergreen-ui';
import { Container, Dropdown, Menu, MenuItemProps, Icon, Sidebar } from 'semantic-ui-react';
import { useWeb3React } from '@web3-react/core';
import { injected, portis } from '../../connectors';
import Logo from '../../svg/Logo';

interface NavbarProps {
  activeItem?: string;
  onItemClick?: (event: React.MouseEvent, data: MenuItemProps) => void;
}

const NavbarMobile: React.FC<NavbarProps> = ({ activeItem, onItemClick }) => {
  const { account, activate, active, deactivate } = useWeb3React();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container className="navbar-mobile-menu-container">
      <Menu secondary pointing fluid className="navbar-mobile-menu">
        <Menu.Item name="logo">
          <Logo width="1.6rem" />
        </Menu.Item>
        <Menu.Item name="icon">
          <Icon
            name={showMenu ? 'angle up' : 'angle down'}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          />
        </Menu.Item>
      </Menu>
      <Sidebar
        as={Container}
        animation={'scale down'}
        direction={'top'}
        visible={showMenu}
        className="home-menu-vertical"
      >
        <Container>
          <Menu stackable secondary pointing>
            <Menu.Item name="events" active={activeItem === 'events'} onClick={onItemClick} />
            <Menu.Item
              name="account"
              active={activeItem === 'account'}
              onClick={onItemClick}
              position="right"
              content="My Account"
            />
            <Menu.Item>
              <Pane display="flex" flexDirection="column" justifyContent="center">
                <Dropdown
                  text={
                    active && account
                      ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
                      : 'Connect Wallet'
                  }
                >
                  {active ? (
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => deactivate()} text="Disconnect" />
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => activate(injected)} text="MetaMask" />
                      <Dropdown.Item onClick={() => activate(portis)} text="Portis" />
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </Pane>
            </Menu.Item>
          </Menu>
        </Container>
      </Sidebar>
    </Container>
  );
};

export default NavbarMobile;
