import React from 'react';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import NewEventModal from '../../components/NewEventModal';
import getContract from '../../utils/getContract';
import getIPFS from '../../utils/ipfs';
import EventDropJSON from '../../contracts/EventDrip.json';
import contractAddress from '../../contracts/contract-address.json';
import tokenAddress from '../../contracts/token-address.json';
import ButtonProps from '../../types/ButtonProps';

const CreateEventButton: React.FC<ButtonProps> = (buttonProps) => {
  const web3React = useWeb3React<Web3Provider>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const ipfs = getIPFS();

  const handleSubmit = React.useCallback(
    async (data) => {
      try {
        const manifestData = {
          ...data,
          timestamp: Math.round(new Date().getTime() / 1000),
        };
        setLoading(true);
        const tokenBits = utils.bigNumberify(10).pow(18);
        const ticketPrice = utils.bigNumberify(data.price).mul(tokenBits);
        const manifest = await ipfs.add(manifestData);
        const eventDrip = getContract(web3React, contractAddress.EventDrip, EventDropJSON.abi);
        await eventDrip.createEvent(
          manifest,
          ticketPrice,
          parseInt(data.capacity, 10),
          tokenAddress.Token,
          data.absorbFees,
        );
        history.push(`/event/${manifest}`);
      } catch (e) {
        console.log(e);
        alert(e.message);
      } finally {
        setLoading(false);
      }
    },
    [web3React, ipfs, history],
  );

  return (
    <>
      <Button primary onClick={() => setOpen(true)} {...buttonProps}>
        Create Event
      </Button>
      <NewEventModal loading={loading} open={open} onClose={() => setOpen(false)} onSubmit={handleSubmit} />
    </>
  );
};

export default CreateEventButton;
