import React from 'react';
import { Pane } from 'evergreen-ui';
import { Card, Image, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ITicket } from '../../types';
import getIPFS from '../../utils/ipfs';

const TicketCard: React.FC<{
  ticket: ITicket;
}> = ({ ticket }) => {
  const history = useHistory();
  const ipfs = getIPFS();
  const { event } = ticket;
  const backgroundImage = event ? ipfs.url(event.image) : undefined;
  const handleGoToEvent = React.useCallback(() => {
    history.push(`/event/${ticket.manifest}`);
  }, [history, ticket]);
  return (
    <Card fluid>
      <Image src={backgroundImage} wrapped ui={false} className={'event-card-image'} />
      <Card.Content>
        <Card.Meta>
          <Pane display="flex" justifyContent="space-between">
            <span className="date">{moment(event.date).format('lll')}</span>
          </Pane>
        </Card.Meta>
        <Card.Header>{event.name}</Card.Header>
        <Card.Description>{event.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button secondary fluid onClick={handleGoToEvent}>
          See Event
        </Button>
      </Card.Content>
    </Card>
  );
};

export default TicketCard;
