import React from 'react';
import { Button } from 'semantic-ui-react';
import ButtonProps from '../../types/ButtonProps';

const AddToCalendarButton: React.FC<ButtonProps> = (buttonProps) => {
  const { style } = buttonProps;
  return (
    <Button primary style={style} {...buttonProps}>
      Add To Calendar
    </Button>
  );
};

export default AddToCalendarButton;
