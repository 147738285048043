import React from 'react';
import { Button } from 'semantic-ui-react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';
import NewMerchModal from '../NewMerchModal';
import getContract from '../../utils/getContract';
import getIPFS from '../../utils/ipfs';
import EventDripJSON from '../../contracts/EventDrip.json';
import contractAddress from '../../contracts/contract-address.json';
import ButtonProps from '../../types/ButtonProps';

const CreateMerchButton: React.FC<ButtonProps> = (buttonProps) => {
  const { event, style } = buttonProps;
  const ipfs = getIPFS();
  const web3React = useWeb3React<Web3Provider>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleAddInventory = React.useCallback(
    async (data) => {
      const manifestData = {
        ...data,
        event,
        timestamp: Math.round(new Date().getTime() / 1000),
      };
      setLoading(true);
      const tokenBits = utils.bigNumberify(10).pow(18);
      const merchPrice = utils.bigNumberify(data.price).mul(tokenBits);
      const merchManifest = await ipfs.add(manifestData);
      const eventDrip = getContract(web3React, contractAddress.EventDrip, EventDripJSON.abi);
      await eventDrip.addInventory(event!.manifest, merchManifest, merchPrice, parseInt(data.maxLimit, 10));
      // TODO: Replace this with proper state reloading
      window.location.reload();
    },
    [event, web3React, ipfs],
  );

  return (
    <>
      <Button primary onClick={() => setOpen(true)} style={style} {...buttonProps}>
        Create Merch
      </Button>
      <NewMerchModal open={open} loading={loading} onClose={() => setOpen(false)} onSubmit={handleAddInventory} />
    </>
  );
};

export default CreateMerchButton;
